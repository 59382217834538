document.addEventListener("turbolinks:load", function(){
    
    $(".form-control").on('change', function(){
    if($('#customer_history_customer_history_type option:selected').text() === "ポンプメンテナンス（年１回）" && $('#customer_history_status option:selected').text() === "完了") {
    $('#maintenanceCheck').show();
    } else {
    $('#maintenanceCheck').hide();
    }
    });

    $('#maintenance').on('click', function(){
    if($('#maintenance').prop('checked')){
    $('#customer_history_maintenance_date').prop('disabled', false);
    $('#maintenanceDate').show();
    } else {
    $('#customer_history_maintenance_date').prop('disabled', true);
    $('#maintenanceDate').hide();
    } 
    });

    
    $(function(){
    if($('#customer_history_customer_history_type').text() === "ポンプメンテナンス（年１回）" && $('#customer_history_status option:selected').text() === "完了") {
    $('#maintenanceCheck').show();
    } else {
    $('#maintenanceCheck').hide();
    }
    });

    // $(function(){
    // if($('#user_birthday').val() != ""){
    //     console.log("aaaa");
    //     var birthdate = $('#user_birthday').val();
    //     var input = document.getElementById("user_hire_date");
    //     input.setAttribute("min", birthdate);
    //     console.log(birthdate);
    // }

    // if($('#user_hire_date').val() != ""){
    //     var hiredate = $('#user_hire_date').val();
    //     var input = document.getElementById("user_birthday");
    //     input.setAttribute("max", hiredate);
    // }
    // });

    // $('#user_birthday').on('change', function(){
    //     var input = document.getElementById("user_hire_date");
    //     input.setAttribute("min", this.value);
    // });

    // $('#user_hire_date').on('change', function(){
    //     var input = document.getElementById("user_birthday");
    //     input.setAttribute("max", this.value);
    // });

    // $('#user_birthday').on('click', function(){
    //     var input = document.getElementById("user_hire_date");
    //     input.setAttribute("min", this.value);
    // });

    // $('#user_hire_date').on('click', function(){
    //     var input = document.getElementById("user_birthday");
    //     input.setAttribute("max", this.value);
    // });

});


    
    